.contact-page {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .contact-header {
    margin-bottom: 30px;
  }
  
  .contact-content {
    margin-bottom: 20px;
  }
  
  .subheader {
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .contact-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .contact-box {
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: box-shadow 0.3s ease-in-out;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
    .contact-box img {
        max-width: 60px;
        margin-bottom: 10px;
    }


  
  .contact-box:hover {
    box-shadow: none;
  }
  
  .box-heading {
    margin-top: 0;
  }
  
  .contact-form {
    margin-bottom: 50px;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 15px;
    text-align: center;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    text-align: center;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: center;
  }
  
  
  .contact-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    align-items: center;
    gap: 0.5rem;
  }
  

  
  
  
  @media (max-width: 480px) {
    .contact-page {
      max-width: 100%;
      padding: 10px;
    }
  
    .contact-header {
      font-size: 24px;
      margin-bottom: 20px;
    }
  
    .subheader {
      font-size: 20px;
      margin-bottom: 5px;
    }
  
    .contact-details {
      grid-template-columns: 1fr;
    }
  
    .contact-box {
      margin-bottom: 15px;
    }
  
    .contact-form {
      margin-bottom: 15px;
    }
  
    input,
    textarea {
      padding: 8px;
    }
  
    .contact-buttons {
      flex-wrap: wrap;
      margin-bottom: 10px;
    }
  
    button {
      margin: 5px;
      padding: 8px 15px;
      font-size: 14px;
    }
  }
  