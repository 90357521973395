/* Footer Styles */

.footer {
    color: black;
    padding: 1rem;
    text-align: center;
  }
  
  .social-networks {
    display: flex;
    justify-content: center;
    padding: 1rem 0;
    border-bottom: 1px solid #dee2e6;
  }
  
  .social-networks-title {
    margin-right: 1rem;
  }
  
  .social-icons {
    display: flex;
    align-items: center;
  }
  
  .social-icon {
    margin-right: 0.5rem;
    color: #6c757d;
  }

  .social-icon i{
    font-size: 1.5rem;
  }
  
  .footer-content {
    padding: 1rem 0;
  }

  .row{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
  }
  
  .footer-title {
    font-size: medium;
    margin-bottom: 0.3rem;
  }

  .row img{
    width: 130px;
    height: 155px;
    margin-bottom: -2rem;
    margin-top: -3rem;
  }

  .row p{
    margin-bottom: 0.4rem;
  }

  .row .tagline{
    margin-top: -1.5rem;
  }
  
  .footer-link {
    text-decoration: none;
    color:var(--color-sec-300);
    display: block;
    margin-bottom: 0.5rem;
    font-weight: var(--font-weight-100);
  }
  
  .footer-link:hover {
    text-decoration: underline;
    color: #D52D27;
  }
  
  .footer-bottom {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 1rem;
  }
  
  .footer-bottom-text {
    margin-bottom: 0;
    color: black;
  }
  
  .footer .icon {
    margin-right: 0.5rem;
    vertical-align: middle;
  }

  .socialdata{
    display:flex;
    gap: 0.5rem;
    cursor: pointer;
  }
  