.HeroContainer {
    max-width: 100%;
    min-height: 0rem;
    background-image: url(../../assets/images/bg-pattern.svg);
    background-repeat: no-repeat;
    background-size: 30.375rem 12.6875rem;
    background-position: 3rem 6.5rem;
  }
  
  @media screen and (min-width: 486px) {
    .HeroContainer {
      background-size: 42.5rem 22rem;
      background-position: 5rem 3.5rem;
    }
  }
  
  @media screen and (max-width: 485px) {
    .HeroContainer {
      background-size: 30.375rem 12.6875rem;
      background-position: 3rem 6.5rem;
    }
  }
  
  @media screen and (min-width: 768px) {
    .HeroContainer {
      background-size: 62.5rem 22rem;
      background-position: 18rem 12.5rem;
    }
  }
  
  @media screen and (min-width: 992px) {
    .HeroContainer {
      background-position: 50rem 12.5rem;
    }
  }
  
  @media screen and (min-width: 1200px) {
    .HeroContainer {
      background-image: none;
    }
  }
  
  .HeroWrap {
    max-width: var(--max-container);
    margin: var(--center-container);
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  @media screen and (min-width: 1168px) {
    .HeroWrap {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
    }
  }
  
  .HeroWrap > * {
    flex: 1;
  }
  
  .HeroContent {
    min-height: 15.93rem;
    max-width: 33.75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0rem 1rem;
  }
  
  @media screen and (min-width: 768px) {
    .HeroContent {
      padding: 0rem;
    }
  }
  
  .HeroContentHeader {
  }
  
  .HeroContentText {
    margin: 1rem 0rem;
  }
  
  @media screen and (min-width: 768px) {
    .HeroContentText {
      margin: 1.5rem 0rem;
    }
  }
  
  .HeroButton {
    display: flex;
    gap: 0.5rem;    
  }
  
  .HeroButton > * {
    margin: 0.3rem;
    width: 10.5rem;
  }


  .linktag{
    text-decoration: none;
    color: #fff;
  }
  
  @media screen and (min-width: 768px) {
    .HeroButton {
      width:23.2375rem;
    }
  
    .HeroButton > * {
      margin: 0rem;
      margin-right: 0.3rem;
    }
  }
  
  .HeroImageWrap {
    min-height: 20.93rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @media screen and (min-width: 480px) {
    .HeroImageWrap {
      min-height: 30.93rem;
    }
  }
  
  @media screen and (min-width: 768px) {
    .HeroImageWrap {
      min-height: 35.93rem;
    }
  }
  
  @media screen and (min-width: 992px) {
    .HeroImageWrap {
      min-height: 35.93rem;
      display: grid;
      place-items: center;
    }
  }
  
  .HeroImage {
  }
  
  @media screen and (min-width: 1200px) {
    .HeroImage {
      transform: translateX(3rem) translateY(2rem);
    }
  }
  