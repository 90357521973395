.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }

  .feedback-button {
    position: fixed;
    bottom: 31rem;
    right: -7rem;
    width: 8rem;
    height: 2.7rem;
    border-radius: 0.5rem 0.5rem 0 0;
    background-color: #D52D27;
    border: none;
    transform: translate(-50%, -50%) rotate(-90deg);
    color: #fff;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease-in-out;
}

.feedback-button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.feedback-button:active {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  transform: scale(0.95) translate(-50%, -50%) rotate(-90deg);
  transition: transform 0.1s ease-in-out;
}
  
  
  
 
  
  form {
    margin-top: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 10px;
  }
  
  select,
  input[type='text'] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  button[type='submit'] {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }
  
  @media screen and (max-width: 600px) {
    .container {
      padding: 10px;
    }
  }
  
  .progresscontainer{
    width:50%;
  }
  